import { Injectable } from '@angular/core';
import { forEach } from 'lodash';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IIconRegistryItem } from '../../models/icon-registry-item.interface';

@Injectable()
export class IconRegistryService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  registerIcons(icons: Array<IIconRegistryItem>): void {
    forEach(icons, (icon: IIconRegistryItem) => {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    });
  }
}
