export const environment = {
  production: false,
  compodocLink: '',
  codeCoverageLink: '',
  storeDevtoolsMaxAge: 25,
  apiUrl: 'https://mpb-bff-develop-ap-southeast-2.mpb-dev.com/api/v1',
  imageRootURLMap: {
    TOYOTA: 'http://static.ifmsystems.com/images/20120618/TMC/Week13/TMC/',
    LEXUS: 'http://static.ifmsystems.com/images/latest/TMC/latest/TMC/',
    HONDA: 'https://static.ifmsystems.com/images/20180510/HCI/latest/HCI/',
    ACURA: 'https://static.ifmsystems.com/images/20180510/HCI/latest/HCI/',
    KIA: 'http://static.ifmsystems.com/images/latest/KIA/latest/KIA/',
    HYUNDAI: 'http://static.ifmsystems.com/images/latest/HYW/latest/HYW/',
    CHRYSLER: 'http://live.origin-us-chr.r53.ifmsystems.com/images/',
  },
  limitedEligibilityForRebateSettings: {
    TOYOTA: {},
    LEXUS: {},
  },
  auth0: {
    domain: 'superservice-test.auth0.com',
    clientId: 'Ana6B6v7bd62bY1r4MRHyP452H228HT6',
    authorizationParams: {
      redirect_uri: window.location.origin + '/auth/redirection',
      audience: 'https://mpb-bff-develop-ap-southeast-2.mpb-dev.com/api/v1',
    },
    responseType: 'token id_token',
  },
};
