import { IIconRegistryItem } from '../../../models/icon-registry-item.interface';

export class IconRegistryConfig {
  static readonly appSvgIcons: Array<IIconRegistryItem> = [
    {
      name: 'archive',
      path: 'assets/svg/archive.svg',
    },
    {
      name: 'home',
      path: 'assets/svg/icon-view-as-grid.svg',
    },
    {
      name: 'activity',
      path: 'assets/svg/activity.svg',
    },
    {
      name: 'icon-close',
      path: 'assets/svg/icon-close.svg',
    },
    {
      name: 'jobs',
      path: 'assets/svg/icon-active-jobs.svg',
    },
    {
      name: 'icon-calendar-month',
      path: 'assets/svg/icon-calendar-month.svg',
    },
    {
      name: 'help',
      path: 'assets/svg/icon-onscreen-help.svg',
    },
    {
      name: 'chrysler-logo',
      path: 'assets/logos/chrysler-logo.svg',
    },
    {
      name: 'daihatsu-logo',
      path: 'assets/logos/daihatsu-logo.svg',
    },
    {
      name: 'datsun-logo',
      path: 'assets/logos/datsun-logo.svg',
    },
    {
      name: 'dodge-logo',
      path: 'assets/logos/dodge-logo.png',
    },
    {
      name: 'fap-logo',
      path: 'assets/logos/fap-logo.svg',
    },
    {
      name: 'feu-logo',
      path: 'assets/logos/feu-logo.svg',
    },
    {
      name: 'fiat-logo',
      path: 'assets/logos/fiat-logo.svg',
    },
    {
      name: 'fna-logo',
      path: 'assets/logos/fna-logo.svg',
    },
    {
      name: 'ford-logo',
      path: 'assets/logos/ford-logo.svg',
    },
    {
      name: 'genesis-logo',
      path: 'assets/logos/genesis-logo.svg',
    },
    {
      name: 'honda-logo',
      path: 'assets/logos/honda-logo.svg',
    },
    {
      name: 'hyundai-logo',
      path: 'assets/logos/hyundai-logo.svg',
    },
    {
      name: 'hyw-logo',
      path: 'assets/logos/hyw-logo.svg',
    },
    {
      name: 'infiniti-logo',
      path: 'assets/logos/infiniti-logo.svg',
    },
    {
      name: 'Infomedia-logo',
      path: 'assets/logos/Infomedia-logo.svg',
    },
    {
      name: 'kia-logo',
      path: 'assets/logos/kia-logo.svg',
    },
    {
      name: 'lexus-logo',
      path: 'assets/logos/lexus-logo.svg',
    },
    {
      name: 'lincoln-logo',
      path: 'assets/logos/lincoln-logo.svg',
    },
    {
      name: 'mopar-logo',
      path: 'assets/logos/mopar-logo.svg',
    },
    {
      name: 'nissan-logo',
      path: 'assets/logos/nissan-logo.svg',
    },
    {
      name: 'toyotaforklift-logo',
      path: 'assets/logos/toyotaforklift-logo.svg',
    },
    {
      name: 'toyota-logo',
      path: 'assets/logos/toyota-logo.svg',
    },
    {
      name: 'toyota-us-logo',
      path: 'assets/logos/toyota-us-logo.svg',
    },
    {
      name: 'vauxhall-logo',
      path: 'assets/logos/vauxhall-logo.png',
    },
    {
      name: 'volkswagen-logo',
      path: 'assets/logos/volkswagen-logo.png',
    },
    {
      name: 'scion-logo',
      path: 'assets/logos/scion-logo.png',
    },
    {
      name: 'ram-logo',
      path: 'assets/logos/ram-logo.png',
    },
    {
      name: 'plymouth-logo',
      path: 'assets/logos/plymouth-logo.png',
    },
    {
      name: 'jeep-logo',
      path: 'assets/logos/jeep-logo.png',
    },
    {
      name: 'eagle-logo',
      path: 'assets/logos/eagle-logo.png',
    },
    {
      name: 'alfa-logo',
      path: 'assets/logos/alfa-logo.png',
    },
    {
      name: 'acura-logo',
      path: 'assets/logos/acura-logo.png',
    },
    {
      name: 'logo_microcatserviceandrepairs',
      path: 'assets/logos/logo_microcatserviceandrepairs.svg',
    },
    {
      name: 'logo_superpservicemenus',
      path: 'assets/logos/logo_superpservicemenus.svg',
    },

    {
      name: 'header-logo',
      path: 'assets/header-logo.svg',
    },
    {
      name: 'lock-logo',
      path: 'assets/svg/lock-24px.svg',
    },
    {
      name: 'icon-more-vert',
      path: 'assets/icons/icon-more-vert.svg',
    },
    {
      name: 'icon-more-horiz',
      path: 'assets/icons/icon-more-horiz.svg',
    },
    {
      name: 'icon-car',
      path: 'assets/icons/icon-car.svg',
    },
    {
      name: 'icon-estimates',
      path: 'assets/icons/icon-estimates.svg',
    },
    {
      name: 'icon-selective-fit',
      path: 'assets/icons/icon-selective-fit.svg',
    },
    {
      name: 'icon-supersession',
      path: 'assets/icons/icon-supersession.svg',
    },
    {
      name: 'icon-alternative-pricing',
      path: 'assets/icons/icon-alternative-pricing.svg',
    },
    {
      name: 'icon-up-hierarchy',
      path: 'assets/icons/icon-up-hierarchy.svg',
    },
    {
      name: 'icon-vehicle-in-circle',
      path: 'assets/icons/icon-vehicle-in-circle.svg',
    },
    {
      name: 'icon-show-applicable-disabled',
      path: 'assets/icons/icon-show-applicable-disabled.svg',
    },
    {
      name: 'icon-show-applicable-enabled',
      path: 'assets/icons/icon-show-applicable-enabled.svg',
    },
    {
      name: 'icon-cog-dialog',
      path: 'assets/icons/icon-cog-dialog.svg',
    },
    {
      name: 'icon-telephone-in-circle',
      path: 'assets/svg/icon-telephone-in-circle.svg',
    },
    {
      name: 'icon-fitment',
      path: 'assets/icons/icon-fitment.svg',
    },
    {
      name: 'icon-order-notes',
      path: 'assets/icons/icon-order-notes.svg',
    },
    {
      name: 'icon-add',
      path: 'assets/icons/icon-add.svg',
    },
    {
      name: 'icon-minus',
      path: 'assets/icons/icon-minus.svg',
    },
    {
      name: 'icon-arrow-right',
      path: 'assets/icons/icon-arrow-right.svg',
    },
    {
      name: 'icon-arrow-drop-down',
      path: 'assets/icons/icon-arrow-drop-down.svg',
    },
    {
      name: 'icon-tech-tip',
      path: 'assets/icons/icon-tech-tip.svg',
    },
    {
      name: 'icon-part-photo',
      path: 'assets/icons/icon-part-photo.svg',
    },
    {
      name: 'icon-retrieve',
      path: 'assets/icons/icon-retrieve.svg',
    },
    {
      name: 'icon-messenger-dark-bg',
      path: 'assets/icons/icon-messenger-dark-bg.svg',
    },
    {
      name: 'icon-package',
      path: 'assets/icons/icon-package.svg',
    },
    {
      name: 'icon-info',
      path: 'assets/icons/icon-info.svg',
    },
    {
      name: 'icon-refresh-blue',
      path: 'assets/icons/icon-refresh-blue',
    },
    {
      name: 'icon-dms-connected',
      path: 'assets/icons/icon-dms-connected.svg',
    },
    {
      name: 'icon-dms-disconnected',
      path: 'assets/icons/icon-dms-disconnected.svg',
    },
    {
      name: 'icon-excel',
      path: 'assets/icons/icon-excel.svg',
    },
    {
      name: 'icon-outline-cart-white',
      path: 'assets/icons/icon-outline-cart-white.svg',
    },
    {
      name: 'icon-outline-cart-green',
      path: 'assets/icons/icon-outline-cart-green.svg',
    },
    {
      name: 'open-white',
      path: 'assets/icons/icon-open-white.svg',
    },
    {
      name: 'icon-smart-toy',
      path: 'assets/icons/icon-smart-toy.svg',
    },
  ];
}
