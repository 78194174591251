import { CanActivateFn, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, tap } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
class AuthenticationGuardService {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  caActivate(): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      take(1),
      tap((auth) => {
        if (!auth) {
          this.router.navigate(['/']);
        }
      }),
    );
  }
}

export const authenticationGuard: CanActivateFn = () => {
  return inject(AuthenticationGuardService).caActivate();
};
