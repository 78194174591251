export const USER_NAME_KEY = 'username';
export const PASSWORD_KEY = 'password';
export const SLASH = '/';
export const UNDERSCORE = '_';
export const QUESTION_MARK = '?';
export const LOCAL_STORAGE = 'LocalStorage';
export const WINDOW = 'Window';
export const SESSION_STORAGE = 'SessionStorage';
export const APP_NAME_KEY = 'appName';
export const APP_NAME_VALUE = 'SMART';
export const EMPTY_STRING_VALUE = '';
export const EMPTY_SPACE = ' ';
export const COMMA = ',';
export const COMMA_SPACE = ', ';
export const DOT = '.';
export const DASH = '-';
export const DASH_WITH_EMPTY_SPACE = `${EMPTY_SPACE}${DASH}${EMPTY_SPACE}`;
export const ESCAPE_BUTTON = 'window:keyup.esc';
export const PIXELS = 'px';
export const DEFAULT_PRICE_VALUE = '0.00';
export const ENTER_KEY = 'Enter';
export const CONTROL_KEY = 'ctrl';
export const ALL = 'ALL';
export const all = 'All';
export const MIXED = 'MIXED';
export const DO_NOT_ORDER = 'Do Not Order';
export const Order = 'Order';
export const QUOTE = 'Quote';
export const NULL_STRING = 'null';
export const DEFAULT_DATETIME_UTC = '0001-01-01T00:00:00Z';
export const DEFAULT_DATETIME_UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const DEFAULT_DATE_UTC_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_LOCALE_FORMAT = 'L';
export const PERCENTS_IN_ENTITY = 100;
export const MICROCAT_MARKET = 'Microcat PartsBridge - ';
export const FR_CA = 'fr-ca';
export const EN_US = 'en-us';
export const PIPE = '|';
export const DOCUMENT = 'Document';
export const DOLLAR_SIGN = '$';
export const END_TIME = '23:59:59Z';
export const HASH = '#';
export const AMPERSAND = '&';
export const EQUALS_SIGN = '=';
export const DEFAULT_TIME_SHORT_FORMAT = 'HH:mm';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const START_TIME = '00:00:00Z';
export const MILLISECONDS_IN_MINUTE = 1000;
export const SHA_256 = 'S256';
export const DAY_OF_WEEK_FORMAT_SHORT = 'ddd';
export const MONTH_FORMAT_LONG = 'MMMM';
export const MONTH_FORMAT_SHORT = 'MMM';
export const DAY_OF_MONTH_FORMAT = 'DD';
export const YEAR_FORMAT_LONG = 'YYYY';
export const DATE_TIME_ZONE = 'Z';
export const PERCENTAGE_SIGN = '%';
