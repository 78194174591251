import { NgModule } from '@angular/core';
import { provideRouter, RouterModule, Routes } from '@angular/router';
import { RoutePathEnum } from './shared/enums/route-path.enum';
import { RootPathRedirectionComponent } from './shared/components/root-path-redirection/root-path-redirection.component';
import { authenticationGuard } from './core/guards/authentication-guard/authentication.guard';

const routes: Routes = [
  {
    path: `${RoutePathEnum.BODYSHOP}`,
    canActivate: [authenticationGuard],
    loadChildren: () => import('./body-shop/body-shop.module').then((m) => m.BodyShopModule),
  },
  {
    path: `${RoutePathEnum.DEALERSHIP}`,
    loadChildren: () => import('./dealership/dealership.module').then((m) => m.DealershipModule),
  },
  {
    path: `${RoutePathEnum.AUTH}`,
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    component: RootPathRedirectionComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: RootPathRedirectionComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [provideRouter(routes)],
})
export class AppRoutingModule {}
