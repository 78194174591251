import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RoutePathEnum } from '../../enums/route-path.enum';

@Component({
  selector: 'mpb-root-path-redirection',
  standalone: true,
  imports: [CommonModule],
  template: `<div class="loading-cursor redirect-container"></div>`,
  styleUrls: ['./root-path-redirection.component.scss'],
})
export class RootPathRedirectionComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated$.pipe(take(1)).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.router.navigate([`${RoutePathEnum.AUTH}/${RoutePathEnum.LOGIN}`]);
        return;
      }

      // TODO: Modify redirection logic once the dealer auth has been implemented
      this.router.navigate([`${RoutePathEnum.BODY_SHOP_TRANSACTIONS}`]);
    });
  }
}
