import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import defaultLanguage from './../assets/i18n/en-us.json';
import { IconRegistryService } from './shared/services/icon-registry/icon-registry.service';
import { IconRegistryConfig } from './shared/services/icon-registry/config/icon-registry-config.const';

@Component({
  selector: 'mpb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private iconRegistryService: IconRegistryService,
  ) {
    this.translate.setTranslation('en-us', defaultLanguage);
    this.translate.setDefaultLang('en-us');
    // TODO: Change the language based on the user preference
    this.translate.use('en-us');
  }

  values: string[] = [];

  ngOnInit(): void {
    this.iconRegistryService.registerIcons(IconRegistryConfig.appSvgIcons);
  }
}
