import { Injectable } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserType } from '../../../shared/enums/user-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpInterceptorService implements HttpInterceptor {
  constructor(private auth0Interceptor: AuthHttpInterceptor) {}

  /* istanbul ignore next */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: Implement dealership interceptor once it ready
    if (req.headers.get('usertype') && req.headers.get('usertype') === UserType.DEALERSHIP) {
      return next.handle(req);
    }

    // return auth0 interceptor for body shop api call
    return this.auth0Interceptor.intercept(req, next);
  }
}
