export enum RoutePathEnum {
  TRANSACTIONS = 'transactions',
  ESTIMATES = 'estimates',
  BODY_SHOP_TRANSACTIONS = 'bodyshop/transactions',
  DEALERSHIP_TRANSACTIONS = 'dealership/transactions',
  BODYSHOP = 'bodyshop',
  DEALERSHIP = 'dealership',
  DEALER = 'dealer',
  BODY_SHOP = 'body-shop',
  ORDER = 'order',
  UI = 'ui',
  LOCALHOST = 'localhost',
  LOGIN = 'login',
  REDIRECTION = 'redirection',
  LOGOUT = 'logout',
  HOME = 'home',
  AUTH = 'auth',
}
